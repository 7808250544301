.modal-base {
  position: absolute;
  top: 20%;
  left: 4%;
  width: 80%;
  height: fit-content;
  border: 1px solid rgb(204, 204, 204);
  background: rgb(255, 255, 255);
  overflow: auto;
  border-radius: 15px;
  outline: none;
  padding: 20px;
}

.modal-title {
  font-size: 1.8em; 
  letter-spacing: 1px;
} 

.modal-content {
  border-top: 1px solid rgb(236, 233, 233); 
  padding: 3em 0;
}

.modal-label {
  font-size: 1.1em; 
  letter-spacing: 1px;
}

.modal-input {
  border: 1px solid lightgray; 
  border-radius: 10px; 
  width: 98%; 
  font-size: 1.8em; 
  padding: 0.2em; 
  margin-top: 0.2em;
}

.modal-button {
  font-size: 1.1em;
  width: fit-content;
  border-radius: 10px;
  padding: 0.6em 1em;
  border: 0px solid;
  letter-spacing: 1px; 
  margin-top: 1.2em;
  background-color: #008EB7;
  color: white;
  float: right;
}

.modal-button:hover {
  cursor: pointer;
  background-color: #0281a4;
}

.error-message {
  position: absolute;
  color: red; 
  font-size: 1em; 
  margin-top: 0.5em;
  letter-spacing: 1px;
}

/* TRY @media (450 <= width < 699px) {} */
@media only screen and (min-width: 450px) and (max-width: 699px) {
  .modal-base {
    width: 70%;
    top: 20%;
    left: 12%;
  }
}

/* TRY @media (width <= 700px) {} */
@media only screen and (min-width: 700px) {
  .modal-base {
    width: 40%;
    top: 20%;
    left: 25%;
  }
}