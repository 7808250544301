.main-title {
  font-size: 3em;
  margin-top: 0.4em;
  margin-bottom: 0.4em;
}

.main-subtitle {
  font-size: 3vh; 
  margin-top: 0.4em; 
  margin-bottom: 1em;
}

.main-logo {
  display: none;
}

.white-main-logo {
  width: 20em;
}

@media only screen and (min-width: 470px) {
  .white-main-logo {
    width: 25em;
  }
}

@media only screen and (min-width: 970px) and (max-width: 1499px) {
  .white-main-logo {
    display: none;
  }

  .main-logo {
    display: block;
    width: 25em;
  }

  .main-title {
    font-size: 3.4em;
    color: #008EB7;
  }

  .main-subtitle {
    font-size: 3.4vh; 
  }
}

@media only screen and (min-width: 1500px) {
  .white-main-logo {
    display: none;
  }

  .main-logo {
    display: block;
    width: 25em;
  }

  .main-title {
    font-size: 4em;
    color: #008EB7;
  }

  .main-subtitle {
    font-size: 4vh; 
  }
}