.form-container {
  min-width: 17em;
  max-width: 30em;
  border-radius: 15px;
  border: 1px solid lightgray;
  padding: 1em;
}

.form-container > div {
  display: flex;
}

.form-container > div:first-child {
  border-bottom: 1px solid lightgray; 
  padding-bottom: 1em;
}

.form-container > div:last-child {
  padding-top: 1em;
}

.form-container > div:not(:first-child):not(:last-child) {
  border-bottom: 1px solid lightgray; 
  padding-top: 1em; 
  padding-bottom: 1em;
}

label:not(.modal-label) {
  font-size: 3vh;
  color: white;
}

input:not(.modal-input), select {
  border: none; 
  min-width: 50%; 
  margin-left: auto; 
  text-align: right; 
  font-size: 3vh;
  background-color: transparent;
  color: white;
}

.message {
  letter-spacing: 1px;
  color: white;
}

@media only screen and (min-width: 970px) and (max-width: 1499px) {
  .form-container {
    min-width: 25em;
    width: 50%; 
  }

  label:not(.modal-label) {
    font-size: 3.4vh;
    color: gray;
  }
  
  input:not(.modal-input), select {
    font-size: 3.4vh;
    color: black;
  }

  .message {
    color: rgb(60, 58, 58);
    width: 75%;
  }
}

@media only screen and (min-width: 1500px) {
  .form-container {
    width: 75%; 
    padding: 1.7em;
  }
  
  label:not(.modal-label) {
    font-size: 3.7vh;
    color: gray;
  }
  
  input:not(.modal-input), select {
    width: 35%; 
    font-size: 4vh;
    color: black;
  }
  
  .message-container {
    position: absolute;
  }

  .message {
    color: rgb(60, 58, 58);
    width: 75%;
  }
}