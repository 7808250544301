main {
  z-index: 10; 
  padding: 2em; 
}

@media only screen and (min-width: 970px) and (max-width: 1499px) {
  main {
    margin-top: -1.5em;
    display: flex; 
    justify-content: space-between; 
    align-items: center;
  }
}


@media only screen and (min-width: 1500px) {
  main {
    margin-top: -1.5em;
    display: flex; 
    justify-content: space-between; 
    align-items: center;
    padding: 4em; 
  }

  main > div {
    width: 50%;
  }
}