section {
  margin-top: 4em;
  display: flex;
  flex-direction: column;
  gap: 2em;
  background-color: white;
  border-radius: 10px;
  padding: 2em 1em;
}

@media only screen and (min-width: 970px) {
  section {
    margin-top: 0;
    background-color: transparent;
    border-radius: 10px;
    padding: none;
  }
}