.result-title {
  font-size: 2.5vh; 
  margin-top: 0.1em; 
  margin-bottom: 0.5em;
  letter-spacing: 2px;
}

.result-text {
  font-size: 3.5vh; 
  color: gray;
  margin-top: 0.1em; 
  margin-bottom: 0.1em;
}

@media only screen and (min-width: 480px) and (max-width: 969px) {
  .result-title {
    text-align: center; 
  }

  .result-text {
    text-align: center; 
  }
}

@media only screen and (min-width: 970px) and (max-width: 1499px) {
  .result-title {
    font-size: 2.7vh; 
    text-align: center; 
  }
  
  .result-text {
    color: white;
    font-size: 4.2vh; 
    text-align: center; 
  }
}

@media only screen and (min-width: 1500px) {
  .result-title {
    font-size: 3vh; 
    text-align: center; 
  }
  
  .result-text {
    color: white;
    font-size: 4.5vh; 
    text-align: center; 
  }
}