@font-face {
  font-family: 'avantGardeGothicDemi';
  src: url('./fonts/avantGardeGothicLTDemiRegular.otf') format('opentype');
}

* {
  font-family: 'avantGardeGothicDemi', sans-serif;
  letter-spacing: 4px;
}

body {
  background-color: #008EB7;
  overflow-x: hidden;
}

.ellipse-bg {
  display: none;
}

.ns-icons-bg {
  display: none;
}

@media only screen and (min-width: 970px) {
  body {
    overflow: hidden;
  }

  .ellipse-bg {
    display: block;
    position: absolute; 
    top: -1em;
    left: -150px;
    z-index: -1;
  }
  
  .ns-icons-bg {
    display: block;
    z-index: -1; 
    position: absolute; 
    top: -1em;
  }
}

@media only screen and (min-width: 970px) and (max-width: 1499px) {
  .ellipse-bg {
    right: 29%;
    height: 110%; 
  }
  
  .ns-icons-bg {
    height: 100%; 
    right: 32%; 
  }
}

@media only screen and (min-width: 1500px) {
  .ellipse-bg {
    right: 40%;
    height: 110%; 
  }

  .ns-icons-bg {
    height: 100%; 
    right: 32%; 
  }
}