header {
  width: 120%;
  position: relative;
  top: 1em;
  right: 2em;
  padding: 1em;
}

header > div {
  margin-left: 2em;
}

.navlink {
  color: white;
  padding: 0.7em 1.2em;
  text-decoration: none;
  transition: transform 0.3s ease;
  letter-spacing: 2px;
  border-radius: 8px;
  background: linear-gradient(145deg, #d9d9d9, #bfbfbf);
  margin: 0 0.3em;
  display: inline-block;
}

.navlink:hover {
  background: linear-gradient(145deg, #bfbfbf, #a6a6a6);
  transform: scale(1.15);
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
  cursor: pointer;
}

.navlink-active {
  background: linear-gradient(145deg, #a6a6a6, #8c8c8c);
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
  color: white !important;
}

@media only screen and (min-width: 970px) {
  .navlink {
    color: rgb(60, 58, 58);
    background: linear-gradient(145deg, #e6e6e6, #cccccc);
  }

  .navlink:hover {
    background: linear-gradient(145deg, #cccccc, #b3b3b3);
    transform: scale(1.15);
  }

  .navlink-active {
    background: linear-gradient(145deg, #b3b3b3, #999999);
  }
}

@media only screen and (min-width: 1500px) {
  header > div {
    margin-left: 4em;
  }
}
